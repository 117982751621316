.de_countdown {
    font-size: 2rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(50, 0, 21, 0.8) 100.94%);
    backdrop-filter: blur(19.4369px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 27px;
    padding: 6px 25px;
    z-index: 1;
    width: 400px;
    height: 100px;
    margin: auto;
    color: white;
}
.de_countdown .Clock-days {
    font-weight: bold;
    display: inline-block;
    
}
.de_countdown .Clock-hours {
    font-weight: bold;
    display: inline-block;
    
}
.de_countdown .Clock-minutes {
    font-weight: bold;
    display: inline-block;
    
}
.de_countdown .Clock-seconds {
    font-weight: bold;
    display: inline-block;
}

@media (max-width: 500px) {
    .de_countdown {
        font-size: 1.2rem;
        background: #212428;
        padding: 6px 15px;
        border-radius: 5px;
        -moz-border-radius: 30px;
        -webkit-border-radius: 30px;
        border: solid 2px #ff69b4;
        z-index: 1;
        width: 220px;
        margin: auto;
        color: white;
    }
  }
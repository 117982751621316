@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');



::-webkit-scrollbar {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

.pie-wrap {
  height: 25%;
  width: 25%;
  position: relative;
}

.inner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-stats {
  font-size: 4.5rem;
  display: flex;
  gap: 5px;
  color: white;
  font-weight: 300;
  align-items: baseline;
  color:#FFB800
}

.small-cap {
  color: white;
  margin-top: -5px;
  font-size: 2rem;

}

.nft-select-value{
  height: 60px;
  width: 60px;
  border-radius: 10px;
  border: #D9D9D9 solid;
}

.nft-selected{
  height: 60px;
  width: 60px;
  border-radius: 10px;
  background-color: #D9D9D9;
  color:black
}

.alata{
  font-family: 'Alata', sans-serif;
}

@font-face {
  font-family: 'berkshire';
  font-weight: 400;
  src: url('fonts/BerkshireSwash-Regular.ttf') format('truetype');
}

.connect-btn-bg{
  background: radial-gradient(87.33% 87.33% at 50% 50%, #FFB800 0%, #975A00 100%);
}

.connected-btn-bg{
  background: radial-gradient(87.33% 87.33% at 50% 50%, #DAFF71 0%, #0B7202 100%);
}

.div-center{
  position: absolute;
  top: 45%;
  left:50%;
  transform: translate(-50%,-50%);
}

.connectBtnBg{
  background: linear-gradient(180deg, rgba(214, 251, 255, 0.15) 0%, rgba(107, 227, 241, 0.29) 100%);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
backdrop-filter: blur(8px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 86px;
}
.gold{
  
  background: linear-gradient(180deg, #FFD541 0%, #A07609 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 3.5px 3.5px rgba(0, 0, 0, 0.2);
  
  
}

.btnTextShadow{
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
}
.roadmapBox{
  background: rgba(255, 255, 255, 0.36);
backdrop-filter: blur(38.5px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 78px;
}
.footerBox{
  background: rgba(2, 37, 41, 0.65);

}
.select-box{
  background: linear-gradient(180deg, rgba(246, 235, 255, 0.47) 9.08%, rgba(54, 1, 96, 0.47) 102.38%);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
backdrop-filter: blur(14.5px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 86px;
}

.congratsModal{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(50, 0, 21, 0.8) 100.94%);
backdrop-filter: blur(39.6382px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 25px;
}

.whales-box{
  background: linear-gradient(180deg, rgba(246, 235, 255, 0.47) 9.08%, rgba(54, 1, 96, 0.47) 102.38%);
  backdrop-filter: blur(14.5px);
  border-radius: 86px;
  margin: auto;
  width: 90%;
}
.income-box{
  background: linear-gradient(180deg, rgba(246, 235, 255, 0.1) 9.08%, rgba(54, 1, 96, 0.1) 102.38%);
backdrop-filter: blur(35.5px);

border-radius: 41px;
  margin: auto;
  width: 85%;
  /* height: 60vh; */
}
.collectToken-box{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(50, 0, 21, 0.8) 100.94%);
  backdrop-filter: blur(24px);
  /* Note: backdrop-filter has minimal browser support */
  
  border-radius: 25px;
  height: 100%;
  width: 35%;
}
.whaleToken-box{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(50, 0, 21, 0.8) 100.94%);
backdrop-filter: blur(24px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 25px;
  width: 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.placeholder-img {

  animation: shimmer 2s infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.108) 4%,
    rgba(255, 255, 255, 0.114) 25%,
    rgba(255, 255, 255, 0.12) 36%
  );
  background-size: 1000px 100%;
}
